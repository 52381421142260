'use client';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps } from '@react-pdf-viewer/default-layout';
import { HighlightArea, highlightPlugin, RenderHighlightsProps, Trigger } from '@react-pdf-viewer/highlight';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { ReactElement, useEffect, useState } from 'react';
import { Text } from './Text';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
type PdfViewerProps = {
  pageIndex: number;
  documentPath: string;
  documentName: string;
  itemId: string;
  highlights: HighlightArea[];
};
export const PdfViewer = ({
  highlights,
  ...props
}: PdfViewerProps) => {
  const {
    pageIndex,
    documentPath,
    documentName,
    itemId
  } = props;
  const toolbarPluginInstance = toolbarPlugin();
  const {
    renderDefaultToolbar
  } = toolbarPluginInstance;
  const [view, setView] = useState<{
    height: number;
    width: number;
  }>({
    height: 841.92,
    width: 595.2
  });
  // divide by 10
  const scaledHighlights = highlights.map(highlight => ({
    ...highlight,
    left: highlight.left / view.width * 75,
    top: highlight.top / view.height * 75,
    width: highlight.width / view.width * 75,
    height: highlight.height / view.height * 75
  }));
  const renderHighlights = (props: RenderHighlightsProps) => <div data-sentry-component="renderHighlights" data-sentry-source-file="PdfViewer.tsx">
      {scaledHighlights.filter(area => area.pageIndex === props.pageIndex).map((area, idx) => <div key={idx} className="highlight-area" style={Object.assign({}, {
      background: 'yellow',
      opacity: 0.4
    },
    // Calculate the position
    // to make the highlight area displayed at the desired position
    // when users zoom or rotate the document
    props.getCssProperties(area, props.rotation))} />)}
    </div>;
  const highlightPluginInstance = highlightPlugin({
    trigger: Trigger.None,
    renderHighlights
  });
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    // These slots will be empty
    // Download: () => <></>,
    // EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
    Open: () => <></>,
    ShowSearchPopover: () => <></>
  });
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      // defaultTabs[0], // Bookmarks tab
      // defaultTabs[1], // Thumbnails tab
    ],
    renderToolbar: (Toolbar: (_props: ToolbarProps) => ReactElement) => {
      return <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
    }
  });
  const [currPage, setCurrPage] = useState<number>(pageIndex);
  const isPdf = /\.pdf(\?|$)/i.test(documentPath);
  useEffect(() => {
    setCurrPage(pageIndex);
  }, [pageIndex, itemId]); // when changing item, reset page index regardless of the current page
  return <div className="w-full " data-sentry-component="PdfViewer" data-sentry-source-file="PdfViewer.tsx">
      <div className="flex justify-between border-2 border-[#3B9167] p-2">
        <Text data-sentry-element="Text" data-sentry-source-file="PdfViewer.tsx">{documentName}</Text>
        {!isPdf && <div className="flex gap-1">
            <Text>
              The select document file format is not supported for visualisation
            </Text>
          </div>}
      </div>
      {isPdf && <div className="border-x-2 border-b-2 border-[#3B9167]">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
            <div style={{
          height: '750px'
        }}>
              <Viewer key={currPage} fileUrl={documentPath} plugins={[defaultLayoutPluginInstance, highlightPluginInstance]} initialPage={currPage} onPageChange={async page => {
            try {
              const viewPort = (await page.doc.getPage(page.currentPage)).getViewport({
                scale: 1
              });
              setView(viewPort);
            } catch (e) {}
          }} />
            </div>
          </Worker>
        </div>}
    </div>;
};